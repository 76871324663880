export const environment = {
   version: '7.10.0',
   production: false,
   apiBaseUrl: 'https://pedemais-api-dev.azurewebsites.net/',
   viaCepBaseUrl: 'https://viacep.com.br/',
   googleApiKey: 'AIzaSyAifx7AaE1TQH260DuEXTqu-_Fm0u3vT2g',
   appInsights: {
      instrumentationKey: 'a14a82f1-c25f-4dcf-a114-f80a3090da32',
   },
   pixPaymentApiUrl: 'https://pix-payment-api-dev.azurewebsites.net/api/',
};
