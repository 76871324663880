import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { take } from 'rxjs/operators';
import { DeliveryParameterization } from '../../models/delivery-service.model';
import { DeliveryServiceGenerateCopyAndPastePixRequest } from '../../models/pix';
import { User } from '../../models/user.model';
import { LoadingService } from '../../services/loading.service';
import { NotificationService } from '../../services/notification.service';
import { PixService } from '../../services/pix.service';
import { UserService } from '../../services/user.service';
import { FunctionService } from '../../utils/function.service';

@Component({
   selector: 'bottom-sheet-pix',
   templateUrl: './pix.component.html',
   styleUrls: ['./pix.component.scss'],
})
export class PixComponent implements OnInit {
   public pixCopyAndPast: any;
   public codeCopied: boolean = false;
   public parameterization: DeliveryParameterization;
   public userInfo: User;
   public cartTotalValue: number;

   constructor(
      private _bottomSheet: MatBottomSheet,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: { parameterization: DeliveryParameterization; cartTotalValue: number },
      private functionService: FunctionService,
      private userService: UserService,
      private loadingService: LoadingService,
      private notificationService: NotificationService,
      private pixService: PixService
   ) {
      this.parameterization = data.parameterization;
      this.cartTotalValue = data.cartTotalValue;
   }

   ngOnInit(): void {
      this.userInfo = this.userService.getUserInfo();
      this.generateCopyAndPastPix();
   }

   generateCopyAndPastPix() {
      const request = new DeliveryServiceGenerateCopyAndPastePixRequest();
      request.CompanyId = this.parameterization.CompanyId;
      request.PixKey = this.parameterization.PixKey;
      request.OrderId = this.functionService.guid();
      request.OrderTotalValue = this.cartTotalValue;
      request.OrderDescription = `${this.userInfo.Name.substring(0, 15)} - R$ ${this.cartTotalValue}`;

      this.loadingService.show();

      this.pixService
         .generateCopyAndPastPix(this.parameterization.CompanyId, request)
         .pipe(take(1))
         .subscribe(
            (ret) => {
               this.pixCopyAndPast = ret;
               this.loadingService.hide();
               this.copyCode();
            },
            (err) => {
               this.loadingService.hide();
               this.notificationService.showAlertBox('GeneratePixCopyAndPastError');
               this.close();
            }
         );
   }

   close() {
      this._bottomSheet.dismiss({ success: this.codeCopied });
   }

   copyCode() {
      this.codeCopied = true;
      this.functionService.copyTextToClipboard(this.pixCopyAndPast, 'PixCopyAndPastCopied');
   }
}
